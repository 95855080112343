var TimeRecord,
  bind = function(fn, me){ return function(){ return fn.apply(me, arguments); }; };

TimeRecord = (function() {
  function TimeRecord() {
    this.showModals = bind(this.showModals, this);
    this.openPreviousModal = bind(this.openPreviousModal, this);
    this.openNewModal = bind(this.openNewModal, this);
    this.handleTimeRecordExportCsvModal = bind(this.handleTimeRecordExportCsvModal, this);
    this.handleRestTimesModal = bind(this.handleRestTimesModal, this);
    this.handleCreateEditRestTimeModal = bind(this.handleCreateEditRestTimeModal, this);
    this.hiddenModelEvents = bind(this.hiddenModelEvents, this);
    this.bindEvents = bind(this.bindEvents, this);
    this.modalsStacks = [];
    this.currentModel = null;
    this.isGoBackModel = false;
    this.bindEvents();
    this.hiddenModelEvents();
  }

  TimeRecord.prototype.bindEvents = function() {
    if (!!$('#time_record_start_time_1i').val() && !$('#time_record_end_time_1i').val() && $('#time_record_end_time_1i').data('isEditing')) {
      $('#time_record_end_time_1i').val($('#time_record_start_time_1i').val());
    }
    if (!!$('#time_record_start_time_2i').val() && !$('#time_record_end_time_2i').val() && $('#time_record_end_time_2i').data('isEditing')) {
      $('#time_record_end_time_2i').val($('#time_record_start_time_2i').val());
    }
    if (!!$('#time_record_start_time_3i').val() && !$('#time_record_end_time_3i').val() && $('#time_record_end_time_3i').data('isEditing')) {
      $('#time_record_end_time_3i').val($('#time_record_start_time_3i').val());
    }
    $('#q_start_time_gteq').datepicker({
      format: 'dd/mm/yyyy'
    });
    $('#q_start_time_lteq').datepicker({
      format: 'dd/mm/yyyy'
    });
    $('#time_record_start_time_photo_fake_input').on('click', function() {
      return $("#time_record_start_time_photo").trigger("click");
    });
    $('#time_record_start_time_photo').on('change', function() {
      return $('#time_record_start_time_photo_fake_value').text($(this).val());
    });
    $('#time_record_end_time_photo_fake_input').on('click', function() {
      return $("#time_record_end_time_photo").trigger("click");
    });
    $('#time_record_end_time_photo').on('change', function() {
      return $('#time_record_end_time_photo_fake_value').text($(this).val());
    });
    $(document).on('click', '#create-rest-time-button', (function(_this) {
      return function() {
        _this.openNewModal('#create-edit-rest-time-modal');
        return _this.handleCreateEditRestTimeModal();
      };
    })(this));
    $(document).on('click', '.edit-rest-time-button', (function(_this) {
      return function(e) {
        var restTimeId;
        restTimeId = $(e.target).data('restTimeId');
        _this.openNewModal('#create-edit-rest-time-modal');
        return _this.handleCreateEditRestTimeModal(restTimeId);
      };
    })(this));
    $('.show-rest-times-button').on('click', (function(_this) {
      return function(e) {
        var timeRecordId;
        timeRecordId = $(e.target).data('timeRecordId');
        _this.openNewModal('#show-rest-times-modal');
        return _this.handleRestTimesModal(timeRecordId);
      };
    })(this));
    return $('.time-record-export-csv-btn').on('click', (function(_this) {
      return function(e) {
        _this.openNewModal('#time-record-export-csv-modal');
        return _this.handleTimeRecordExportCsvModal();
      };
    })(this));
  };

  TimeRecord.prototype.hiddenModelEvents = function() {
    return $('.modal').on('hidden.bs.modal', (function(_this) {
      return function(e) {
        return _this.openPreviousModal();
      };
    })(this));
  };

  TimeRecord.prototype.handleCreateEditRestTimeModal = function(restTimeId) {
    var restTimeURL, timeRecordId;
    timeRecordId = $('#rest_times_table').data('timeRecordId');
    restTimeURL = Routes.new_time_record_rest_time_path(timeRecordId);
    if (!!restTimeId) {
      restTimeURL = Routes.edit_time_record_rest_time_path(timeRecordId, restTimeId);
    }
    return $.ajax({
      url: restTimeURL,
      type: 'get',
      dataType: 'html',
      success: (function(_this) {
        return function(resp) {
          return $('#create-edit-rest-time-modal .modal-body').html(resp);
        };
      })(this)
    });
  };

  TimeRecord.prototype.handleRestTimesModal = function(timeRecordId) {
    return $.ajax({
      url: Routes.time_record_rest_times_path(timeRecordId),
      type: 'get',
      dataType: 'html',
      success: (function(_this) {
        return function(resp) {
          return $('#show-rest-times-modal .modal-body').html(resp);
        };
      })(this)
    });
  };

  TimeRecord.prototype.handleTimeRecordExportCsvModal = function() {
    return $.ajax({
      url: Routes.export_csv_time_records_path(),
      type: 'get',
      dataType: 'html',
      success: (function(_this) {
        return function(resp) {
          $('#time-record-export-csv-modal .modal-body').html(resp);
          return $('#time-record-date-for-export').datepicker({
            format: 'dd/mm/yyyy'
          });
        };
      })(this)
    });
  };

  TimeRecord.prototype.openNewModal = function(newModal) {
    this.isGoBackModel = false;
    $(this.currentModel).modal('hide');
    if (this.modalsStacks.indexOf(newModal) < 0) {
      this.modalsStacks.push(newModal);
    }
    if (this.currentModel !== null && $(this.currentModel).length > 0) {
      return $(this.currentModel).on('hidden.bs.modal', (function(_this) {
        return function() {
          return _this.showModals();
        };
      })(this));
    } else {
      return this.showModals();
    }
  };

  TimeRecord.prototype.openPreviousModal = function() {
    if (this.isGoBackModel || this.modalsStacks.length <= 1) {
      this.modalsStacks.pop();
      this.showModals();
    }
    return this.isGoBackModel = true;
  };

  TimeRecord.prototype.showModals = function() {
    var modal;
    modal = this.modalsStacks[this.modalsStacks.length - 1];
    this.currentModel = modal;
    if (!!modal) {
      return $(modal).modal('show');
    }
  };

  return TimeRecord;

})();

module.exports = TimeRecord