# class Staffs
#   constructor: ->
#     @bindEvents()

#   bindEvents: =>
#     $('.staff-export-csv-btn').on 'click', (e) =>
#       $('#staff-export-csv-modal').modal()
#       id = $(e.target).data('id')
#       @handleStaffExportCsvModal(id)

#   handleStaffExportCsvModal: (id) =>
#     $.ajax
#       url: Routes.export_csv_staffs_path()
#       type: 'get'
#       dataType: 'html'
#       data:
#         id: id
#       success: (resp) =>
#         $('#staff-export-csv-modal .modal-body').html(resp)
#         $('#start_date').datepicker({
#           format: 'dd/mm/yyyy'
#         });
#         $('#end_date').datepicker({
#           format: 'dd/mm/yyyy'
#         });

# export default Staffs

# The above code is faulty on iOS 9
Staffs = undefined

bind = (fn, me) ->
  ->
    fn.apply me, arguments

Staffs = do ->
  `var Staffs`

  Staffs = ->
    @handleStaffExportCsvModal = bind(@handleStaffExportCsvModal, this)
    @bindEvents = bind(@bindEvents, this)
    @bindEvents()
    return

  Staffs::bindEvents = ->
    $('.staff-export-csv-btn').on 'click', ((_this) ->
      (e) ->
        id = undefined
        $('#staff-export-csv-modal').modal()
        id = $(e.target).data('id')
        _this.handleStaffExportCsvModal id
    )(this)

  Staffs::handleStaffExportCsvModal = (id) ->
    $.ajax
      url: Routes.export_csv_staffs_path()
      type: 'get'
      dataType: 'html'
      data: id: id
      success: ((_this) ->
        (resp) ->
          $('#staff-export-csv-modal .modal-body').html resp
          $('#start_date').datepicker format: 'dd/mm/yyyy'
          $('#end_date').datepicker format: 'dd/mm/yyyy'
      )(this)

  Staffs

export default Staffs