// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.jQuery = $
window.$ = $
window.Routes = require('routes.js.erb')

require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("bootstrap-datepicker")
require("@fortawesome/fontawesome-free/js/all")
require("javascripts/theme_plugins/bootstrap")
require("javascripts/jquery.timepicker.min")
require("javascripts/select2.min")
require("javascripts/adminlte")

import * as moment from 'moment'
import TimeRecord from '../pages/time_records'
import Staffs from '../pages/staffs'

window.moment = moment
window.TimeRecord = TimeRecord
window.Staffs = Staffs
// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
